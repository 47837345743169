<template>
  <div id="scraper-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-card-list"></i>
          <span style="margin-left: 5px"> Log Tier Media </span>
        </b-card-title>
      </b-card-header>
      <!-- body component -->
      <b-card-body
        class="d-flex flex-wrap align-items-center pb-0 justify-content-end gap-5px"
      >
        <b-button
          :variant="
            filter_data.hide_filled_description ? 'success' : 'outline-success'
          "
          class="btn-icon"
          v-b-tooltip.hover.top="'Exclude Deskripsi yang Sudah Diisi'"
          @click="
            (filter_data.hide_filled_description = !filter_data.hide_filled_description),
              getData()
          "
        >
          <i class="bi bi-check-circle"></i>
        </b-button>
        <!-- old tier filter -->
        <div>
          <select
            v-model="filter_data.old_tier"
            id="Tier"
            class="form-control"
            @change="getData()"
          >
            <option value="">Semua Tier Lama</option>
            <option value="0">Untier</option>
            <option value="1">Tier 1</option>
            <option value="2">Tier 2</option>
            <option value="3">Tier 3</option>
            <option value="4">Tier 4</option>
          </select>
        </div>
        <!-- new tier filter -->
        <div>
          <select
            v-model="filter_data.new_tier"
            id="Tier"
            class="form-control"
            @change="getData()"
          >
            <option value="">Semua Tier Baru</option>
            <option value="0">Untier</option>
            <option value="1">Tier 1</option>
            <option value="2">Tier 2</option>
            <option value="3">Tier 3</option>
            <option value="4">Tier 4</option>
          </select>
        </div>
        <!-- date picker -->
        <dateRangePicker
          :from_date="filter_data.from_date"
          :to_date="filter_data.to_date"
          @update-value="dateFilterChanged"
        />
        <!-- keyword filter -->
        <b-input-group class="input-group-merge" style="width: 20rem">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="filter_data.key"
            placeholder="Masukkan kata kunci..."
          />
        </b-input-group>
      </b-card-body>
      <div class="mt-1">
        <!-- loader component -->
        <loader v-if="is_loading" />
        <!-- table component -->
        <dataTable
          v-else
          :data="table_data"
          :viewDetail="addDescription"
          :is_row_custom_color="true"
          :is_pagination="false"
        />
        <paginationInfo
          :page="pagination.page"
          :items="pagination.items"
          :count="pagination.count"
          @update-value="paginationChanged"
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import dateRangePicker from "@/views/components/dateRangePicker.vue";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import paginationInfo from "../components/paginationInfo.vue";

export default {
  name: "LogMediaTier",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    dataTable,
    paginationInfo,
    dateRangePicker,
    loader,
  },
  data() {
    return {
      is_loading: true,
      filter_data: {
        from_date:
          this.$route?.query?.from_date ||
          moment(new Date())
            .subtract(7, "days")
            .format("YYYY-MM-DD 00:00:00"),
        to_date:
          this.$route?.query?.to_date ||
          moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        old_tier: "",
        new_tier: "",
        key: "",
        hide_filled_description: true,
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "scraper_name",
            label: "nama scraper",

            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "file_name",
            label: "nama file",

            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "media_name",
            label: "media terkait",
            thClass: "text-left",
            tdClass: "text-left",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "media_count",
            label: "jumlah media",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "old_tier",
            label: "tier lama",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "new_tier",
            label: "tier baru",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },

          {
            key: "log_tier_description",
            label: "deskripsi",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "created_at",
            label: "tanggal diubah di monalisa",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      cancel_token: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "filter_data.key"() {
      if (
        this.filter_data.key.trim().length >= 2 ||
        this.filter_data.key.trim().length == 0
      ) {
        this.getData();
      }
    },
  },
  methods: {
    dateFilterChanged(data) {
      this.filter_data.from_date = data.from_date;
      this.filter_data.to_date = data.to_date;
      this.getData();
    },
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      let params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        hide_filled_description: this.filter_data.hide_filled_description,
        page: this.pagination.page,
        item: this.pagination.items,
        ...(this.filter_data.old_tier
          ? { old_tier: this.filter_data.old_tier }
          : {}),
        ...(this.filter_data.new_tier
          ? { new_tier: this.filter_data.new_tier }
          : {}),
        ...(this.filter_data.key ? { key: this.filter_data.key } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "tier-log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res.data.tier_logs_data || [];
          this.pagination.count = res.data.pagination_info.count || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    addDescription(id) {
      const selected_data = this.table_data.items.find((el) => el._id == id);
      if (
        selected_data &&
        selected_data.description &&
        selected_data.description.trim()
      ) {
        const params = {
          scraper_name: selected_data.scraper_name,
          description: selected_data.description || "",
        };
        useJwt.axiosIns
          .put(`tier-log/update/${selected_data._id}`, params)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Berhasil !",
                icon: "AlertCircleIcon",
                variant: "success",
                text: `Deskripsi Telah Ditambahkan!`,
              },
            });
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Gagal !",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: `Terjadi Kesalahan Pada Sistem!`,
              },
            });
          })
          .finally(() => {
            this.getData();
          });
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
